import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { prepBotHistoryGPT, useAzureRecordingSTT } from '@trainhq/trainhq-client-core';
import { SpeakerAudioDestination } from 'microsoft-cognitiveservices-speech-sdk';
import { interval, map, Subject, Subscription, take, takeUntil, timer } from 'rxjs';

import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import { useMicPermissionDialog } from '@/roleplay/naturalRolePlay/callRolePlay/micPermissionDialog/MicPermissionDialog';
import {
  AvatarStyled,
  CallIconWrapper,
  MainBoxStyled,
  PhoneIconStyled,
  PulsingBackgroundDivSmallStyled,
  PulsingBackgroundDivStyled,
  StaticBackgroundDivStyled
} from '@/roleplay/naturalRolePlay/callRolePlay/styles';
import { useCallTimeoutDialog } from '@/roleplay/naturalRolePlay/callRolePlay/timeoutDialog/CallTimeoutDialog';
import ChallengesPanel from '@/roleplay/naturalRolePlay/common/challengesPanel/ChallengesPanel';
import TitleCard from '@/roleplay/naturalRolePlay/common/titleCard/TitleCard';
import XButton from '@/roleplay/naturalRolePlay/common/xButton/XButton';
import { sidebarGoals, sidebarObjections, TTSReadMessage } from '@/roleplay/naturalRolePlay/utils';
import { microphonePermissionObservable } from '@/utils/userAgentUtils';

interface CallRolePlayProps {
  chatMessages: any[];
  coveredSteps: any[];
  initialSentence?: boolean;
  player?: SpeakerAudioDestination;
  sessionUuid: string;
  determineErrorHandling(error: any): void;
  onBackButtonClick(): void;
  pauseBot(): void;
  readMessage(readMessageProps: TTSReadMessage): void;
  setChatMessages: React.Dispatch<React.SetStateAction<any[]>>;
  setCoveredSteps: React.Dispatch<React.SetStateAction<any[]>>;
  toggleValidationDialog(props?: { onBeforeSubmit?: () => void; onClose?: () => void; onRestart?: () => void }): void;
}

// TODO: avoid prop drilling
// TODO: move useEffects to separate hooks for better code readability
const CallRolePlay: React.FC<CallRolePlayProps> = ({
  chatMessages,
  coveredSteps,
  initialSentence,
  sessionUuid,
  determineErrorHandling,
  onBackButtonClick,
  pauseBot,
  readMessage,
  setChatMessages,
  setCoveredSteps,
  toggleValidationDialog
}) => {
  const {
    roleplayConfig,
    roleplayExecutionConfig,
    roleplaySummary,
    scriptConfig,
    synthesizer,
    providePlayerEndCallback,
    refreshRoleplaySummary,
    setRoleplaySummary
  } = useAzureSDKContext();
  const authenticatedUserContext = useAuthenticatedUserContext();
  const rpService = useRolePlayService();
  const callTimeoutDialog = useCallTimeoutDialog();
  const micPermissionDialog = useMicPermissionDialog();

  const {
    recording,
    startingRecord,
    recognizedSpeech,
    setRecognizedSpeech,
    speechEndDetected,
    speechStartDetected,
    setSpeechEndDetected,
    startRecording,
    stopRecording,
    voiceDetected,
    recognitionEndedAsync
  } = useAzureRecordingSTT({ stt: roleplayExecutionConfig, rpService });

  // callState controls call action button behavior and animation
  const [callState, setCallState] = useState<'call' | 'inCall'>('call');
  const [botTalking, setBotTalking] = useState<boolean>(false);
  const [botIsThinking, setBotIsThinking] = useState<boolean>(false);
  // connected state controls if the call is actually connected (recording && callState === 'inCall')
  const [connected, setConnected] = useState<boolean>(false);

  // this has to be reffed in order to be able to access it in the callback
  const validationDialogOpen = useRef<boolean>(false);
  const preventBotReadingOnLeave = useRef<boolean>(false);
  const timerSubscription = useRef<Subscription>(null);
  const learnersTurn = !botIsThinking && !botTalking && callState === 'inCall';

  const textToRenderBelowBotName =
    callState === 'call'
      ? roleplayConfig?.botTitle
      : !connected
      ? 'Connecting...'
      : botIsThinking
      ? `${roleplayConfig?.botName} is thinking...`
      : learnersTurn && !speechStartDetected
      ? 'It’s your turn'
      : '';

  const handleCloseCallRoleplay = useCallback(() => {
    onBackButtonClick();
  }, [onBackButtonClick]);

  const handleOnRestartCallback = useCallback(() => {
    setCallState('call');
    setConnected(false);
    setConnected(false);
    setBotIsThinking(false);
    setBotTalking(false);
    pauseBot();
    timerSubscription?.current?.unsubscribe?.();
    setChatMessages([]);
    setRecognizedSpeech('');
  }, [pauseBot, setChatMessages, setRecognizedSpeech]);

  const startDialogTimer = useCallback(() => {
    if (!validationDialogOpen.current) {
      // stop pulsing the bot icon
      setBotTalking(false);

      // start recording the learner after the bot finishes speaking
      startRecording();

      const notifier = new Subject<void>();
      timerSubscription.current = new Subscription();

      const $timer = timer(0, 1000).pipe(
        map((seconds) => {
          if (seconds >= parseInt(roleplayConfig?.botSpeechEndDetectionTimeout) / 1000) {
            notifier.next();
            stopRecording();
            callTimeoutDialog(handleOnRestartCallback);
          }
        }),
        takeUntil(notifier)
      );

      timerSubscription.current.add($timer.subscribe());
    }
  }, [
    startRecording,
    roleplayConfig?.botSpeechEndDetectionTimeout,
    stopRecording,
    callTimeoutDialog,
    handleOnRestartCallback
  ]);

  // callback for azure player end speech
  useEffect(() => {
    if (providePlayerEndCallback) {
      providePlayerEndCallback(() => startDialogTimer);
    }
  }, [providePlayerEndCallback, startDialogTimer]);

  useEffect(() => {
    // if a learner starts talking while the timer counts to the end, stop the timer
    if (speechStartDetected && timerSubscription.current) {
      timerSubscription.current.unsubscribe();
    }
  }, [speechStartDetected]);

  const handleSubmitMessage = useCallback(() => {
    setBotIsThinking(true);

    const history = prepBotHistoryGPT({
      chatMessages: chatMessages,
      hasInitialSentence: !!initialSentence,
      prepForSubmit: false,
      // updatedChatMessages: updatedChatHistory,  TODO: see if this is needed
      botName: roleplayConfig.botName,
      userName: roleplayConfig.userName
    });

    //  TODO: check if updatedChatHistory is needed like in chat example
    const newChatMessagesHistory = [...chatMessages.filter((item, index) => item.content || index === 0)];
    setChatMessages(['', { content: recognizedSpeech, sender: 'me' }, ...newChatMessagesHistory]);

    setSpeechEndDetected(false);

    rpService
      .respond({
        sessionUuid,
        step: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
        covered_steps: coveredSteps,
        company: authenticatedUserContext?.activeCompany?.uuid,
        learner_input: recognizedSpeech,
        fail_occurred: false // TODO: check what's this
        // fail_occurred: failPresent
      })
      .subscribe({
        next: (res) => {
          // if (res?.status === null) {
          //   setErrorInCommunication(true);
          //   setChatStatus('failure');
          //   return;
          // }
          // setWaitForReponse(false);
          // if (res?.status) {
          //   setChatStatus(res?.status);
          // }
          if (res?.status === 'proceed') {
            // setShowHint(false);
            if (res.actual_step || res.actual_step === 0) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
            const newChatMessages = [
              { content: res.message.replace(/^\n|\n$/g, ''), sender: 'bot' },
              { content: recognizedSpeech.replace(/^\n|\n$/g, ''), sender: 'me' },
              ...chatMessages
            ];

            // check if validation dialog is open, if user clicked the end call button
            // if it's open, do not read the message
            // TODO: this can be improved - the message does not have to be concatenated above in newChatMessages
            // if (!validationDialogOpen.current) {
            //
            // }
            // start pulsing the bot icon and remove the 'bot is thinking' copy'
            setBotIsThinking(false);
            setBotTalking(true);
            if (!preventBotReadingOnLeave?.current) {
              readMessage({
                message: res.message,
                chatMessages: newChatMessages,
                expression: res.expression ?? roleplayConfig?.overallExpression ?? 'general',
                // callback for openai player end speech
                messageEndCallback: startDialogTimer
              });
            }
          } else {
            if (res?.status === 'failure' && !roleplaySummary.completed) {
              // setFailPresent(true);
              setRoleplaySummary({ ...roleplaySummary, finishedInARow: 0 });
              refreshRoleplaySummary().subscribe();
            }
            // setInputHint(res?.message);
            // if (!failPresent && res?.status !== null && res?.status !== 'failure') {
            if (res?.status !== null && res?.status !== 'failure') {
              setRoleplaySummary({
                ...roleplaySummary,
                finishedInARow: roleplaySummary.finishedInARow + 1
              });
            }
            setChatMessages([{ sender: 'me', content: recognizedSpeech }, ...chatMessages]);
            if (res?.status !== 'failure' && (res.actual_step || res.actual_step === 0)) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
          }
        },
        error: (e) => {
          if (e.errorBody.error) {
            determineErrorHandling(e);
          }
        }
      });
    setRecognizedSpeech('');
  }, [
    chatMessages,
    initialSentence,
    roleplayConfig.botName,
    roleplayConfig.userName,
    roleplayConfig?.overallExpression,
    setChatMessages,
    recognizedSpeech,
    setSpeechEndDetected,
    rpService,
    sessionUuid,
    coveredSteps,
    authenticatedUserContext?.activeCompany?.uuid,
    setRecognizedSpeech,
    setCoveredSteps,
    readMessage,
    startDialogTimer,
    roleplaySummary,
    setRoleplaySummary,
    refreshRoleplaySummary,
    determineErrorHandling
  ]);

  const handleToggleRecording = () => {
    if (startingRecord) {
      // prevent the 'click' event from being triggered. Should work like if the button is disabled
      return;
    } else if (callState === 'inCall') {
      // setValidationDialogOpen is used to detect whether to trigger reading the bot message when
      // the end call button is clicked while the inference is in progress
      validationDialogOpen.current = true;
      if (recording) {
        stopRecording();
      }
      // timer subscription does not exist if the user clicks on end call button before the first message is sent
      if (timerSubscription?.current) {
        timerSubscription.current.unsubscribe();
      }
      toggleValidationDialog({
        onClose: () => {
          // reset recognized speech in order to reset the learners last sentence,
          // so when cancel is clicked the bot does not say it again
          setRecognizedSpeech('');
          startRecording();
          pauseBot();
          validationDialogOpen.current = false;
          startDialogTimer();
        },
        onBeforeSubmit: () => {
          stopRecording();
          if (timerSubscription?.current) {
            timerSubscription.current.unsubscribe();
          }
        },
        onRestart: () => {
          validationDialogOpen.current = false;
          stopRecording();
          handleOnRestartCallback();
        }
      });
      //   if the bot speaks first:
    } else if (roleplayConfig?.starterMessage) {
      microphonePermissionObservable().subscribe({
        next: () => {
          setCallState('inCall');
          setBotTalking(true);
          setConnected(true);
          readMessage({
            message: roleplayConfig?.starterMessage,
            chatMessages: [],
            expression: roleplayConfig?.overallExpression || 'general',
            // callback for openai player end speech
            messageEndCallback: startDialogTimer
          });
        },
        error: () => {
          micPermissionDialog(handleOnRestartCallback);
        }
      });
    } else {
      microphonePermissionObservable().subscribe({
        next: () => {
          setCallState('inCall');
          startDialogTimer();
        },
        error: () => {
          micPermissionDialog(handleOnRestartCallback);
        }
      });
    }
  };

  // start the 1 sec recording delay timer so browsers can catch beginning of the speech
  useEffect(() => {
    const subs = new Subscription();
    if (recording && callState === 'inCall') {
      subs.add(
        interval(1000)
          .pipe(take(1))
          .subscribe(() => {
            setConnected(true);
          })
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [callState, recording]);

  useEffect(() => {
    if (speechEndDetected && !recognitionEndedAsync) {
      if (recognizedSpeech && recording) {
        // stop recording the learner when speech ends and stop the timer
        timerSubscription?.current?.unsubscribe();
        stopRecording();
        handleSubmitMessage();
      }
    }
  }, [handleSubmitMessage, recognitionEndedAsync, recognizedSpeech, recording, speechEndDetected, stopRecording]);

  // handles back and X button clicks cleanup
  useEffect(() => {
    return () => {
      timerSubscription?.current?.unsubscribe?.();
      pauseBot();
      stopRecording();
      preventBotReadingOnLeave.current = true;
    };
  }, [pauseBot, stopRecording]);

  return (
    <MainBoxStyled>
      <Grid container justifyContent="space-between">
        <Grid item>
          <TitleCard name={roleplayConfig?.name} />
        </Grid>
        <Grid item>
          <XButton onClick={handleCloseCallRoleplay} />
        </Grid>
      </Grid>
      <Grid container marginTop="16px" flexGrow={1} maxHeight="calc(100% - 92px)">
        {roleplayConfig?.challengesSidebarEnabled && (
          <Grid item minWidth="336px" maxWidth="336px!important" maxHeight="100%" xs={12} sm="auto">
            <ChallengesPanel
              rolePlayName={roleplayConfig?.name}
              goals={sidebarGoals(roleplayExecutionConfig?.rolePlaySections, scriptConfig?.jsonGoals)}
              objections={sidebarObjections(roleplayExecutionConfig?.rolePlayObjections, scriptConfig?.objectionsBank)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm display="flex">
          <Grid container direction="column" height="100%">
            <Grid item alignContent="end" height="60%">
              <Grid alignItems="center" container direction="column" justifyContent="center" paddingBottom="10px">
                <Grid item position="relative">
                  <PulsingBackgroundDivStyled pulsing={botTalking || voiceDetected} />
                  <PulsingBackgroundDivSmallStyled pulsing={botTalking || voiceDetected} />
                  <StaticBackgroundDivStyled />
                  {learnersTurn ? (
                    <AvatarStyled
                      colorRandomizeString={`${authenticatedUserContext?.firstName ?? 'a'}${
                        authenticatedUserContext?.lastName ?? 'b'
                      }`}
                    >
                      <Typography color="common.zima" fontSize="60px" fontWeight="60px">
                        {authenticatedUserContext?.firstName?.[0] || ''}
                        {authenticatedUserContext?.lastName?.[0] || ''}
                      </Typography>
                    </AvatarStyled>
                  ) : (
                    <AvatarStyled src={roleplayConfig?.botImage} />
                  )}
                </Grid>
              </Grid>
              <Grid alignItems="center" container direction="column" justifyContent="center">
                <Grid item>
                  <Typography color="common.zima" fontWeight={700}>
                    {learnersTurn ? 'You' : roleplayConfig?.botName}
                  </Typography>
                </Grid>
                <Grid item>
                  {/* height of 24.5px is fixed here so when there's no text to display, the elements don't jump */}
                  <Typography color="common.zima" fontSize={14} height="24.5px">
                    {textToRenderBelowBotName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignContent="end" height="40%">
              <Grid container justifyContent="center" paddingBottom="16px">
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <CallIconWrapper state={callState} onClick={handleToggleRecording}>
                        <PhoneIconStyled />
                      </CallIconWrapper>
                    </Grid>
                    <Grid item marginTop="8px">
                      <Typography align="center" color="common.zima">
                        {callState === 'call' ? 'Start' : 'End'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainBoxStyled>
  );
};

export default CallRolePlay;
